<script>
import ChartistProfitCustomer from "@/components/ChartistProfitCustomer.vue";


/**
 * Revenue Analytics component
 */
export default  {
  components: {ChartistProfitCustomer},
  methods: {
    change_header_data(header){
      this.totalProfit = header.totalProfit
      this.countOrders = header.countOrders
      this.countCustomers = header.countCustomers
    },
    change(){

      this.labels = ["from method"]
    }
  },
  mounted() {
    this.change_header_data({countOrders:7,countCustomers:7,totalProfit:7})
  },
  data() {
    return {
      countCustomers : 5,
      countOrders : 7,
      totalProfit : 1,

      series: [
        {
          name: "2020",
          type: "column",
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        },
        {
          name: "2019",
          type: "line",
          data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
        }
      ],

      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }
    };
  },


};
</script>

<template>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Line Chart</h4>
          <div class="row text-center">
            <div class="col-sm-4">
              <h5 class="mb-0">{{ countCustomers }}</h5>
              <p class="text-muted">Count Customers</p>
            </div>
            <div class="col-sm-4">
              <h5 class="mb-0">{{ countOrders }}</h5>
              <p class="text-muted">Count Orders</p>
            </div>
            <div class="col-sm-4">
              <h5 class="mb-0">{{ totalProfit }}</h5>
              <p class="text-muted">Total Profit</p>
            </div>
          </div>
          <!-- Line Chart -->
          <ChartistProfitCustomer @change_header_data="change_header_data" :height="300"  />
        </div>
      </div>
    </div>
  </div>

</template>